import {
  Chart as ChartJS,
  CategoryScale,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Filler,
} from "chart.js";
import React from "react";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Filler
);

interface Norm {
  name: string;
  mean: number;
  sd: number;
  precision: number;
  granularity: number;
}

interface NormalDistributionProps {
  activeScale: Norm;
  score: number;
}

const NormalDistribution = ({
  activeScale,
  score,
}: NormalDistributionProps) => {
  const calculatePDF = (x: number, mean: number, sd: number) => {
    const pdf =
      (1 / (Math.sqrt(2 * Math.PI) * sd)) *
      Math.exp(-Math.pow(x - mean, 2) / (2 * Math.pow(sd, 2)));
    return pdf;
  };

  const generateData = React.useCallback(() => {
    const points: number[] = [];
    const labels: number[] = [];
    const fill: boolean[] = [];
    const steps = 10;
    const standardDeviations = 3;
    const min = activeScale.sd * -standardDeviations;
    const mid = activeScale.mean;
    const max = activeScale.sd * standardDeviations;
    const rounding = /*activeScale.name === "z Score" ? 0.1 :*/ 0.1;
    for (
      let i = min;
      i < max + activeScale.sd / steps;
      i += activeScale.sd / steps
    ) {
      points.push(calculatePDF(i + mid, activeScale.mean, activeScale.sd));
      const xValue = Math.round((i + mid) * (1 / rounding)) / (1 / rounding);
      labels.push(xValue);
      if (score > xValue) {
        fill.push(true);
      } else {
        fill.push(false);
      }
    }
    return { points, labels, fill };
  }, [activeScale, score]);

  const [data, setData] = React.useState(generateData);

  React.useEffect(() => {
    setData(generateData);
  }, [activeScale, generateData]);

  const generateDatasets = React.useCallback(() => {
    return {
      labels: data.labels,
      datasets: [
        {
          data: data.points,
          fill: true,
          borderColor: "rgb(102, 123, 104)",
          borderWidth: 3,
          pointRadius: 0,
          tension: 0.35,
          segment: {
            backgroundColor: (context: { p0DataIndex: number }) => {
              const index = context.p0DataIndex;
              if (data.fill[index]) {
                return "rgba(163, 184, 153, 0.5)";
              }
              return "rgba(0, 0, 0, 0)";
            },
            borderColor: "rgb(102, 123, 104)",
          },
        },
      ],
    };
  }, [data]);

  const [datasets, setDatasets] = React.useState(generateDatasets);

  React.useEffect(() => {
    setDatasets(generateDatasets);
  }, [activeScale, generateDatasets]);

  const options = {
    responsive: true,
    scales: {
      y: {
        grid: {
          tickWidth: 0,
          tickLength: 7,
        },
      },
      x: {
        ticks: {
          autoSkip: true,
          maxTicksLimit: 14,
        },
        grid: {
          tickWidth: 0,
          tickLength: 7,
        },
        title: {
          display: true,
          text: activeScale.name,
        },
      },
    },
  };

  return <Line data={datasets} options={options} />;
};

export default NormalDistribution;
