import React from "react";
import "./Calculator.css";
import DescriptorSelector from "../DescriptorSelector/DescriptorSelector";
import NormalDistribution from "../NormalDistribution/NormalDistribution";

interface Norm {
  name: string;
  mean: number;
  sd: number;
  precision: number;
  granularity: number;
}

interface Norms {
  [key: string]: Norm;
}

interface CalculatorProps {
  norms: Norms;
  convertScore: (value: number, from: Norm, to: Norm) => number;
  getScoreDescription: (value: number, from: Norm) => string;
  getPercentile: (value: number, from: Norm) => number;
  descriptorType: string;
  setDescriptorType: (value: string) => void;
}

const Calculator = ({
  norms,
  convertScore,
  getScoreDescription,
  getPercentile,
  descriptorType,
  setDescriptorType,
}: CalculatorProps) => {
  const [activeScale, setActiveScale] = React.useState<Norm>(norms.z);

  interface ScoresObject {
    [key: string]: string;
  }
  const initializeScores = (): ScoresObject => {
    const scoresObj: ScoresObject = {};
    const normArray = Object.values(norms);
    for (let norm of normArray) {
      scoresObj[norm.name as keyof ScoresObject] = String(
        convertScore(0, norms.z, norm) // Intialize with z Score at 0
      );
    }
    scoresObj.percentile = String(getPercentile(0, norms.z));
    scoresObj.description = String(getScoreDescription(0, norms.z));
    return scoresObj;
  };
  const [scores, setScores] = React.useState(initializeScores);

  const handleScoreChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    scale: Norm
  ): void => {
    if (isNaN(Number(event.target.value))) {
      return;
    }
    setScores((prevScores) => {
      const newInputObj: ScoresObject = { ...prevScores };
      newInputObj[scale.name as keyof ScoresObject] = event.target.value;
      return newInputObj;
    });
    if (isNaN(parseInt(event.target.value))) {
      // Allow minus to be typed for negative numbers
      return;
    } else {
      calculateAndUpdateScores();
    }
  };

  const handleFocus = (
    event: React.ChangeEvent<HTMLInputElement>,
    scale: Norm
  ): void => {
    event.target.select();
    setActiveScale(scale);
  };

  const calculateAndUpdateScores = React.useCallback((): void => {
    setScores((prevScores) => {
      const newScores: ScoresObject = { ...prevScores };
      const normArray = Object.values(norms);
      for (let norm of normArray) {
        newScores[norm.name as keyof ScoresObject] = String(
          convertScore(Number(newScores[activeScale.name]), activeScale, norm)
        );
      }
      newScores.percentile = String(
        getPercentile(Number(newScores[activeScale.name]), activeScale)
      );
      if (newScores.percentile === "100") {
        newScores.percentile = ">99.9";
      } else if (newScores.percentile === "0") {
        newScores.percentile = "<0.1";
      }
      newScores.description = getScoreDescription(
        Number(newScores[activeScale.name]),
        activeScale
      );
      return newScores;
    });
  }, [activeScale, convertScore, getPercentile, getScoreDescription, norms]);

  const handleDescriptorChange = (descriptor: string) => {
    setDescriptorType(descriptor);
  };
  React.useEffect(() => {
    calculateAndUpdateScores();
  }, [descriptorType, calculateAndUpdateScores]);

  return (
    <form
      className="calculator"
      onSubmit={(event) => {
        event.preventDefault();
        calculateAndUpdateScores();
      }}
    >
      {Object.values(norms).map((norm, index) => {
        return (
          <div className="scaleContainer" key={index}>
            <label htmlFor={norm.name} className="scaleLabel">
              {norm.name}
            </label>
            <input
              id={norm.name}
              type="number"
              step="any"
              autoComplete="off"
              className={`score${
                activeScale.name === norm.name ? " active" : ""
              }`}
              value={scores[norm.name]}
              onChange={(event) => handleScoreChange(event, norm)}
              onFocus={(event) => handleFocus(event, norm)}
            ></input>
          </div>
        );
      })}
      <div className="seletorContainer">
        <DescriptorSelector
          descriptorType={descriptorType}
          changeHandler={handleDescriptorChange}
          labelText="Qualitative Descriptor Type"
        />
      </div>
      <div className="nonConvertibleContainer">
        <div className="scaleContainer percentileContainer">
          <div className="scaleLabel">— Percentile —</div>
          <div className="scoreLike percentile">{scores.percentile}</div>
        </div>
        <div className="scaleContainer descriptionContainer">
          <div className="scaleLabel">— Description —</div>
          <div className="scoreLike description">{scores.description}</div>
        </div>
      </div>
      <button type="submit">Convert Scores</button>
      <div className="smallDividerContainer">
        <div className="smallDivider"></div>
      </div>
      <NormalDistribution
        activeScale={activeScale}
        score={Number(scores[activeScale.name])}
      />
    </form>
  );
};

export default Calculator;
