import React from "react";
import "./App.css";
import Calculator from "./components/Calculator/Calculator";
import PlusMinusControls from "./components/PlusMinusControls/PlusMinusControls";
import Table from "./components/Table/Table";
import DescriptorSelector from "./components/DescriptorSelector/DescriptorSelector";

function App() {
  interface Norm {
    name: string;
    mean: number;
    sd: number;
    precision: number;
    granularity: number;
  }

  interface Norms {
    [key: string]: Norm;
  }

  const norms: Norms = {
    z: { name: "z Score", mean: 0, sd: 1, precision: 100, granularity: 0.1 },
    t: { name: "T Score", mean: 50, sd: 10, precision: 10, granularity: 1 },
    iq: {
      name: "Standard Score",
      mean: 100,
      sd: 15,
      precision: 1,
      granularity: 1,
    },
    scaled: {
      name: "Scaled Score",
      mean: 10,
      sd: 3,
      precision: 10,
      granularity: 1,
    },
  };

  const convertScore = React.useCallback(
    (value: number, from: Norm, to: Norm): number => {
      if (to === from) {
        return value;
      }
      let newValue: number = ((value - from.mean) * to.sd) / from.sd + to.mean;
      newValue = Math.round(newValue * to.precision) / to.precision;
      return newValue;
    },
    []
  );

  const getPercentile = React.useCallback(
    (value: number, from: Norm): number => {
      // https://stackoverflow.com/questions/16194730/seeking-a-statistical-javascript-function-to-return-p-value-from-a-z-score
      // http://math2.org/math/stat/distributions/z-dist.htm

      const zScore: number =
        from.name === "z Score" ? value : convertScore(value, from, norms.z);

      if (zScore < -6.5) return 0;
      if (zScore > 6.5) return 100;

      let factK = 1;
      let sum = 0;
      let term = 1;
      let k = 0;
      const loopStop = Math.exp(-23);
      while (Math.abs(term) > loopStop) {
        // Note: 1/sqrt(2*pi) = 0.3989422804
        term =
          (((0.3989422804 * Math.pow(-1, k) * Math.pow(zScore, k)) /
            (2 * k + 1) /
            Math.pow(2, k)) *
            Math.pow(zScore, k + 1)) /
          factK;
        sum += term;
        k++;
        factK *= k;
      }
      sum += 0.5;
      sum *= 100;

      return Math.round(sum * 10) / 10;
    },
    [convertScore, norms.z]
  );

  const [descriptorType, setDescriptorType] = React.useState("wechsler");
  const [scaleToSortBy, setScaleToSortBy] = React.useState(norms.t);

  React.useEffect(() => {
    if (
      descriptorType === "wechsler" ||
      descriptorType === "capClinic" ||
      descriptorType === "hemOnc"
    ) {
      if (scaleToSortBy.name !== "Standard Score") {
        setScaleToSortBy(norms.iq);
      }
    } else {
      if (scaleToSortBy.name !== "T Score") setScaleToSortBy(norms.t);
    }
  }, [descriptorType, norms.iq, norms.t, scaleToSortBy]);

  const getScoreDescription = React.useCallback(
    (value: number, from: Norm): string => {
      const iqScore: number =
        from.name === "Standard Score"
          ? value
          : Math.round(convertScore(value, from, norms.iq));
      const tScore: number =
        from.name === "Standard Score"
          ? value
          : Math.round(convertScore(value, from, norms.t));
      switch (descriptorType) {
        case "wechsler":
          if (iqScore >= 130) {
            return "Extremely High";
          } else if (iqScore >= 120 && iqScore <= 129) {
            return "Very High";
          } else if (iqScore >= 110 && iqScore <= 119) {
            return "High Average";
          } else if (iqScore >= 90 && iqScore <= 109) {
            return "Average";
          } else if (iqScore >= 80 && iqScore <= 89) {
            return "Low Average";
          } else if (iqScore >= 70 && iqScore <= 79) {
            return "Very Low";
          } else if (iqScore <= 69) {
            return "Extremely Low";
          } else {
            return "error";
          }
        case "capClinic":
          if (iqScore >= 130) {
            return "Very Superior";
          } else if (iqScore >= 121 && iqScore <= 129) {
            return "Superior";
          } else if (iqScore >= 111 && iqScore <= 120) {
            return "High Average";
          } else if (iqScore >= 90 && iqScore <= 110) {
            return "Average";
          } else if (iqScore >= 81 && iqScore <= 89) {
            return "Low Average";
          } else if (iqScore >= 71 && iqScore <= 80) {
            return "Borderline Low";
          } else if (iqScore <= 70) {
            return "Very Low";
          } else {
            return "error";
          }
        case "hemOnc":
          if (iqScore >= 130) {
            return "Exceptionally High";
          } else if (iqScore >= 120 && iqScore <= 129) {
            return "Above Average";
          } else if (iqScore >= 111 && iqScore <= 119) {
            return "High Average";
          } else if (iqScore >= 90 && iqScore <= 110) {
            return "Average/Age-Typical";
          } else if (iqScore >= 81 && iqScore <= 89) {
            return "Low Average";
          } else if (iqScore >= 71 && iqScore <= 80) {
            return "Below Average";
          } else if (iqScore <= 70) {
            return "Exceptionally Low";
          } else {
            return "error";
          }
        case "masc2":
          if (tScore >= 70) {
            return "Very Elevated";
          } else if (tScore >= 65 && tScore <= 69) {
            return "Elevated";
          } else if (tScore >= 60 && tScore <= 64) {
            return "Slightly Elevated";
          } else if (tScore >= 55 && tScore <= 59) {
            return "High Average";
          } else if (tScore >= 40 && tScore <= 54) {
            return "Average";
          } else if (tScore < 40) {
            return "Low";
          } else {
            return "error";
          }
        case "cdi2":
          if (tScore >= 70) {
            return "Very Elevated Score";
          } else if (tScore >= 65 && tScore <= 69) {
            return "Elevated Score";
          } else if (tScore >= 60 && tScore <= 64) {
            return "High Average Score";
          } else if (tScore >= 40 && tScore <= 59) {
            return "Average Score";
          } else if (tScore < 40) {
            return "Low Score";
          } else {
            return "error";
          }
        case "brief2":
          if (tScore >= 70) {
            return "Clinically Elevated";
          } else if (tScore >= 65 && tScore <= 69) {
            return "Potentially Clinically Elevated";
          } else if (tScore >= 60 && tScore <= 64) {
            return "Mildly Elevated";
          } else if (tScore < 60) {
            return "- out of range -";
          } else {
            return "error";
          }
        case "conners3":
          if (tScore >= 70) {
            return "Very Elevated Score";
          } else if (tScore >= 65 && tScore <= 69) {
            return "Elevated Score";
          } else if (tScore >= 60 && tScore <= 64) {
            return "High Average Score";
          } else if (tScore >= 40 && tScore <= 59) {
            return "Average Score";
          } else if (tScore < 40) {
            return "Low Score";
          } else {
            return "error";
          }
        case "srs":
          if (tScore >= 76) {
            return "Severe";
          } else if (tScore >= 66 && tScore <= 75) {
            return "Moderate";
          } else if (tScore >= 60 && tScore <= 65) {
            return "Mild-to-moderate";
          } else if (tScore <= 59) {
            return "Low-to-none";
          } else {
            return "error";
          }
        case "cbcl":
          if (tScore >= 70) {
            return "Clinical Range";
          } else if (tScore >= 65 && tScore <= 69) {
            return "Borderline Range";
          } else if (tScore < 65) {
            return "Normal range";
          } else {
            return "error";
          }
        default:
          return "error";
      }
    },
    [descriptorType, convertScore, norms.iq, norms.t]
  );

  const [standardDeviations, setStandardDeviations] = React.useState(3);

  const adjustSD = (adjustment: number) => {
    if (standardDeviations === 1 && adjustment < 0) {
      return;
    } else if (standardDeviations === 5 && adjustment > 0) {
      return;
    }
    setStandardDeviations((prev) => prev + adjustment);
  };

  return (
    <div className="App">
      <h1>PSYCHO&shy;METRIC CONVERSION TOOLS</h1>
      <div className="sectionHeading calculatorHeading">
        <h2>— Calculator —</h2>
        <p>This calculator converts between different normative scores.</p>
        <p>
          Enter a score into its corresponding number field. The scores are
          automatically converted as you type.
        </p>
        <p>Change the qualitative descriptor type from the dropdown menu.</p>
      </div>
      <Calculator
        norms={norms}
        convertScore={convertScore}
        getScoreDescription={getScoreDescription}
        getPercentile={getPercentile}
        descriptorType={descriptorType}
        setDescriptorType={setDescriptorType}
      />
      <div className="sectionDivider"></div>
      <div className="sectionHeading tableHeading">
        <h2>— Table —</h2>
        <p>This is a conversion lookup table for the same normative scores.</p>
        <p>
          Click on the column header that you're converting{" "}
          <span className="italic">from</span> to modify the table based on that
          norm (no more decimal values or duplicates to sift through).
        </p>
        <p>
          Click again on the same score to toggle ascending/descending order.
        </p>
        <DescriptorSelector
          descriptorType={descriptorType}
          changeHandler={setDescriptorType}
          labelText="Select the qualitative descriptor type here:"
        />
        <p>Adjust the number of standard deviations to display here:</p>
        <PlusMinusControls
          value={standardDeviations}
          handleAdjustment={adjustSD}
        />
      </div>
      <Table
        norms={norms}
        convertScore={convertScore}
        getScoreDescription={getScoreDescription}
        getPercentile={getPercentile}
        standardDeviations={standardDeviations}
        scaleToSortBy={scaleToSortBy}
      />
    </div>
  );
}

export default App;
