import React from "react";
import "./Table.css";

interface Norm {
  name: string;
  mean: number;
  sd: number;
  precision: number;
  granularity: number;
}

interface Norms {
  [key: string]: Norm;
}

interface TableProps {
  norms: Norms;
  convertScore: (value: number, from: Norm, to: Norm) => number;
  getScoreDescription: (value: number, from: Norm) => string;
  getPercentile: (value: number, from: Norm) => number;
  standardDeviations: number;
  scaleToSortBy: Norm;
}

const Table = ({
  norms,
  convertScore,
  getScoreDescription,
  getPercentile,
  standardDeviations,
  scaleToSortBy = norms.t,
}: TableProps) => {
  const [activeScale, setActiveScale] = React.useState(scaleToSortBy);
  const [isAscending, setIsAscending] = React.useState(false);
  const handlePercentileBookends = (percentile: number): string => {
    let percentileStr: string = String(percentile);
    if (percentile === 100) {
      percentileStr = ">99.9";
    } else if (percentile === 0) {
      percentileStr = "<0.01";
    }
    return percentileStr;
  };

  React.useEffect(() => {
    setActiveScale(scaleToSortBy);
  }, [scaleToSortBy]);

  const handleHeaderClick = (norm: Norm): void => {
    setActiveScale(norm);
    if (activeScale.name === norm.name && !isAscending) {
      setIsAscending(true);
    } else {
      setIsAscending(false);
    }
  };

  return (
    <div className="tableContainer">
      <table>
        <thead>
          <tr>
            {Object.values(norms).map((norm, index) => {
              return (
                <th
                  key={index}
                  className={
                    activeScale.name === norm.name
                      ? "clickable activeCol"
                      : "clickable"
                  }
                  onClick={() => handleHeaderClick(norm)}
                >
                  <div className="thContainer">
                    <span className="thText">{norm.name}</span>
                    {activeScale.name === norm.name ? (
                      <span className="thSymbol">
                        {isAscending ? "\u00a0▲" : "\u00a0▼"}
                      </span>
                    ) : null}
                  </div>
                </th>
              );
            })}
            <th>Percentile</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {[
            ...Array(
              (activeScale.sd * (standardDeviations * 2)) /
                activeScale.granularity +
                1
            ),
          ].map((n, index) => {
            let unroundedScore: number;
            if (isAscending) {
              unroundedScore =
                activeScale.mean -
                (activeScale.sd * standardDeviations -
                  index * activeScale.granularity);
            } else {
              unroundedScore =
                activeScale.mean +
                (activeScale.sd * standardDeviations -
                  index * activeScale.granularity);
            }
            const score =
              Math.round(unroundedScore * (1 / activeScale.granularity)) /
              (1 / activeScale.granularity);
            return (
              <tr key={index}>
                {Object.values(norms).map((norm) => {
                  return (
                    <td key={norm.name}>
                      {convertScore(score, activeScale, norm)}
                    </td>
                  );
                })}
                <td>
                  {handlePercentileBookends(getPercentile(score, activeScale))}
                </td>
                <td className="tdDescription">
                  {getScoreDescription(score, activeScale)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
