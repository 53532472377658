import "./DescriptorSelector.css";

interface DescriptorSelectorProps {
  labelText: string;
  descriptorType: string;
  changeHandler: (value: string, event: React.ChangeEvent<EventTarget>) => void;
}

const DescriptorSelector = ({
  labelText,
  descriptorType,
  changeHandler,
}: DescriptorSelectorProps) => {
  return (
    <>
      <label htmlFor="descriptor" className="descriptorLabel">
        {labelText}
      </label>
      <select
        id="descriptor"
        value={descriptorType}
        className="descriptorSelect"
        onChange={(event) => {
          changeHandler(event.target.value, event);
        }}
      >
        <option value="wechsler">Wechsler</option>
        <option value="capClinic">CAP Clinic</option>
        <option value="masc2">MASC 2</option>
        <option value="cdi2">CDI 2</option>
        <option value="brief2">BRIEF 2</option>
        <option value="conners3">Conners 3</option>
        <option value="srs">SRS</option>
        <option value="cbcl">CBCL</option>
        <option value="hemOnc">HemOnc Clinic</option>
      </select>
    </>
  );
};

export default DescriptorSelector;
