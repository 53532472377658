import "./PlusMinusControls.css";

interface PlusMinusControlsProps {
  value: number;
  handleAdjustment: (adjustment: number) => void;
}
const PlusMinusControls = ({
  value,
  handleAdjustment,
}: PlusMinusControlsProps) => {
  return (
    <div className="plusMinusControls">
      <div className="decrease noSelect" onClick={() => handleAdjustment(-1)}>
        -
      </div>
      <div className="displayedValue">{value}</div>
      <div className="increase noSelect" onClick={() => handleAdjustment(1)}>
        +
      </div>
    </div>
  );
};

export default PlusMinusControls;
